import React from "react";

import { styles } from "./style";

const RetroLandingPage = () => {
  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>Hello Researcher!</h1>
        {/* <p style={styles.subtitle}>A personalized weekly newsletter</p> */}
      </header>

      <a href="https://forms.gle/gEccLgtLbuAcx1dP9">
        <button style={styles.button}>Subscribe to know when it ships!</button>
      </a>

      {/* <main style={styles.main}> */}
      {/* <h2 style={styles.h2}>What we offer weekly:</h2> */}
      {/* <ul style={styles.ul}> */}
      {/* <li>Newly released research papers relevant to your research.</li> */}
      {/* <li>Introduction to other researchers in your field.</li> */}
      {/* <li>New Research ideas for your next award-winning project.</li> */}
      {/* </ul> */}
      {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>{" "} */}
      {/* </main> */}

      <footer style={styles.footer}>&copy; {new Date().getFullYear()}</footer>
    </div>
  );
};

export default RetroLandingPage;
