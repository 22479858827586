export const styles = {
  container: {
    backgroundColor: "#fefcbf",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    //   padding: "1rem",
    fontFamily: "monospace",
  },
  header: {
    textAlign: "center",
    marginBottom: "2rem",
  },
  title: {
    fontSize: "3.75rem",
    fontWeight: "bold",
    color: "#6b46c1",
    marginBottom: "0.5rem",
  },
  subtitle: {
    fontSize: "1.5rem",
    color: "#dd6b20",
  },
  main: {
    backgroundColor: "white",
    border: "4px solid #6b46c1",
    padding: "1.5rem",
    maxWidth: "28rem",
    width: "100%",
  },
  h2: {
    fontSize: "1.875rem",
    fontWeight: "bold",
    color: "#2f855a",
    marginBottom: "1rem",
  },
  ul: {
    listStyleType: "disc",
    paddingLeft: "1.5rem",
    color: "#2b6cb0",
  },
  button: {
    marginTop: "1.5rem",
    backgroundColor: "#feb24c",
    color: "black",
    padding: "0.5rem 1rem",
    borderRadius: "0.25rem",
    border: "none",
    cursor: "pointer",
  },
  footer: {
    marginTop: "2rem",
    color: "#6b46c1",
  },
};
