import React from "react";

import { styles } from "./style";

const TermsPage = () => {
  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>Hello Researcher</h1>
        <p style={styles.subtitle}>Terms</p>
      </header>

      <main style={styles.main}>
        {/* <h2 style={styles.h2}>:</h2> */}
        <ol>
          <li>We collect your email, name, and publication list.</li>
          <li>
            We run algorithms to recommend papers and other researchers to you.
          </li>
          <li>
            We don't sell your email, name, or publication list to third
            parties.
          </li>
          <li>
            We will delete all your data at your request. Email us at
            delete@helloresearcher.com.
          </li>
          <li>In the future, we might show ads to you as income revenue.</li>
        </ol>
      </main>

      <footer style={styles.footer}>&copy; {new Date().getFullYear()}</footer>
    </div>
  );
};

export default TermsPage;
